/* 页面头部 */
.pg-header {
  background: var(--anzhiyu-card-bg);
  border-radius: 12px;
  padding: 40px;
  margin-bottom: 30px;
  border: 1px solid var(--anzhiyu-card-border);
}

.pg-header-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.pg-header-icon {
  font-size: 2.8rem;
  color: var(--anzhiyu-main);
}

.pg-header-info h2 {
  font-size: 2rem;
  margin: 0;
  color: var(--anzhiyu-text);
  font-weight: 600;
}

.pg-header-info p {
  font-size: 1rem;
  color: var(--anzhiyu-secondtext);
  margin: 8px 0;
}

.pg-header-info time {
  font-size: 0.9rem;
  color: var(--anzhiyu-fontcolor);
  opacity: 0.8;
}

/* 主要内容区 */
.pg-content {
  margin-bottom: 30px;
}

.pg-block {
  background: var(--anzhiyu-card-bg);
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 25px;
  border: 1px solid var(--anzhiyu-card-border);
}

.pg-block-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.pg-block-header i {
  font-size: 1.5rem;
  color: var(--anzhiyu-main);
}

.pg-block-header h2 {
  margin: 0;
  font-size: 1.8rem;
  color: var(--anzhiyu-text);
}

/* 卡片网格布局 */
.pg-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 10px;
}

.pg-card {
  background: var(--anzhiyu-card-bg);
  border-radius: 10px;
  padding: 20px;
  border: 1px solid var(--anzhiyu-card-border);
}

.pg-card-title {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.pg-card-title i {
  color: var(--anzhiyu-main);
  font-size: 1.2rem;
}

.pg-card-title h3 {
  margin: 0;
  font-size: 1.3rem;
  color: var(--anzhiyu-text);
}

/* 列表样式 */
.pg-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
}

.pg-list-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: var(--anzhiyu-card-bg);
  border-radius: 8px;
  border: 1px solid var(--anzhiyu-card-border);
}

.pg-list-item i {
  font-size: 1.5rem;
  color: var(--anzhiyu-main);
}

/* 联系方式 */
.pg-contact {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.pg-contact-link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  color: var(--anzhiyu-text);
  text-decoration: none;
}